import type { FetchError } from "ofetch";
import type { Ref } from "vue";
import type { IChangePasswordModel, IUpdateAccountModel, IUpdateSettingModel, IMessageResponse, IUserModel } from "~/models";
import type { ApiErrorResponse } from "~/utils/types";
import { customFetch } from "~/utils/custom-fetch";

type SpentPointsType = {
  amount: number;
  reason: string;
  content_id?: number;
  is_creator?: boolean;
};

export const userProfile = async (username: string, refresh?: boolean) => {
  const url = refresh ? `account/profile/${username}?refresh=1` : `account/profile/${username}`;

  const { data, error } = await useFetchWithCache<{
    data: IUserModel;
    meta: {
      can_subscribe: boolean;
    };
  }>(url, {
    key: `account/profile/${username}`,
  });

  return { data, error: error as Ref<FetchError<ApiErrorResponse<undefined>> | null> };
};

export const userProfileClient = async (username: string) => {
  return customFetch<{
    data: IUserModel;
    meta: {
      can_subscribe: boolean;
    };
  }>(`account/profile/${username}`);
};

export const updateAccount = async (updateAccount: IUpdateAccountModel) => {
  return customFetch<IMessageResponse, IUpdateAccountModel>("account/me", {
    method: "PUT",
    body: updateAccount,
  });
};

export const updateSettings = async (updateSetting: IUpdateSettingModel) => {
  return customFetch<IMessageResponse, IUpdateSettingModel>("account/update-settings", {
    method: "POST",
    body: updateSetting,
  });
};

export const changePassword = async (changePassword: IChangePasswordModel) => {
  return customFetch<IMessageResponse, IChangePasswordModel>("account/change-password", {
    method: "POST",
    body: changePassword,
  });
};

export const spendPointsOnServer = async (auth: string, body: SpentPointsType) => {
  const config = useRuntimeConfig();

  const baseUrl = config.public.apiBaseUrl;
  const accessKey = config.apiServerAccessKey;

  try {
    await $fetch<{ data: string }>(`${baseUrl}/account/points/spend`, {
      method: "POST",
      headers: {
        "Authorization": auth,
        "x-system-access-key": accessKey,
      },
      body,
    });
  } catch {
    // do nothing
  }
};
