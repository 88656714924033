export default defineNuxtPlugin(() => {
  const { enabledCookies } = useCookieConsent();
  const { initialize } = useGtag();

  const cookie = enabledCookies.value.find((cookie) => cookie.id === "analytics");

  if (cookie && cookie.enabled) {
    initialize();
  }
});
