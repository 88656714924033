import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, fromUnixTime } from "date-fns";
import type { IUploadWidgetModel, IFlutterwavePaymentOptionsModel } from "~/models";
import type { ApiErrorResponse, CloudinaryUploadCallback } from "./types";
import { marked } from "marked";
import { markdownToTxt } from "markdown-to-txt";
import emojiRegex from "emoji-regex";
import DOMPurify from "dompurify";
import { CODE_REPLACEMENT_SENTENCES } from "~/constants";

export const addParamsToRoute = (uri: string, patterns: Array<[string, string]>) => {
  for (const pattern of patterns) {
    uri = uri.replaceAll(pattern[0], pattern[1]);
  }

  return uri;
};

export const formatSeconds = (seconds: number) => {
  return seconds >= 60 * 60 ? new Date(seconds * 1000).toISOString().slice(11, 19) : new Date(seconds * 1000).toISOString().slice(14, 19);
};

export const objectToNameValueArray = (dataObject: object, includeOnlyProps?: string[]): Array<{ name: string; value: string | unknown }> => {
  const arr = Object.entries(dataObject).map((entry) => ({ name: entry[0], value: entry[1] }));

  if (includeOnlyProps?.length > 0) {
    return arr.filter((item) => includeOnlyProps?.includes(item.name));
  }

  return arr;
};

export const createCloudinaryWidget = (options: IUploadWidgetModel, resultCallback: CloudinaryUploadCallback) => {
  /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
  // @ts-ignore
  return cloudinary.createUploadWidget(options, resultCallback);
};

export const getValidationError = <T>(property: string, errors?: ApiErrorResponse<T> | null): string => {
  if (!errors || !errors.errors) {
    return "";
  }

  return errors.errors[property] && errors.errors[property].length > 0 ? errors.errors[property][0] : "";
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const generateId = () => {
  return Math.random().toString(36).slice(2) + Date.now().toString(36);
};

export const getRandomIntInclusive = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const truncate = (str?: string | null, maxCount: number = 200) => {
  if (!str) {
    return "";
  }

  return str.length > maxCount ? str.slice(0, maxCount - 1) + "..." : str;
};

export const diffForHumans = (timestamp: number) => {
  const date = fromUnixTime(timestamp);

  const days = differenceInDays(new Date(), date);

  if (days >= 1) {
    return `${days} ${days > 1 ? "days ago" : "day ago"}`;
  }

  const hours = differenceInHours(new Date(), date);

  if (hours >= 1) {
    return `${hours} ${hours > 1 ? "hours ago" : "hour ago"}`;
  }

  const mins = differenceInMinutes(new Date(), date);

  if (mins >= 1) {
    return `${mins} ${mins > 1 ? "minutes ago" : "minute ago"}`;
  }

  return `${differenceInSeconds(new Date(), date)} seconds ago`;
};

export const getApiRequestHeaders = (token?: string | null) => {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

export const scrollToPageTop = (timeoutInMs: number = 0) => {
  window.setTimeout(() => {
    document.querySelector("#app-container")?.scrollTo({
      top: 0,
    });
  }, timeoutInMs);
};

export const makeFlutterwavePayment = (options: IFlutterwavePaymentOptionsModel) => {
  return FlutterwaveCheckout(options);
};

export const sanitizeMarkdown = (message: string) => {
  if (!message) {
    return "";
  }

  message = message.replaceAll(/^\n|\n$/g, "");

  const preMarkdown = "```markdown\n";

  if (message.startsWith(preMarkdown)) {
    message = message.slice(preMarkdown.length);
  }

  const postMarkdown = "\n```";

  if (message.endsWith(postMarkdown)) {
    message = message.slice(0, message.length - postMarkdown.length);
  }

  return message;
};

export const safeHTML = (htmlString: string, useDomPurify = true) => {
  if (!document) {
    return htmlString;
  }

  if (useDomPurify) {
    return DOMPurify.sanitize(htmlString);
  }

  return manualSanitizeHTML(htmlString);
};

export const manualSanitizeHTML = (htmlString: string) => {
  const doc = document.implementation.createHTMLDocument("");
  const div = doc.createElement("div");

  div.innerHTML = htmlString;

  for (let elements = div.querySelectorAll("*"), i = elements.length - 1; i >= 0; i--) {
    const element = elements[i],
      tagName = element.localName;

    if (tagName == "script" || tagName == "noscript" || tagName == "noembed" || !(element.attributes instanceof NamedNodeMap)) {
      try {
        element.remove();
      } catch {
        element.outerHTML = "";
      }

      continue;
    }

    if (!element.hasAttributes()) continue;

    for (let attributes = element.attributes, j = attributes.length - 1; j >= 0; j--) {
      const attribute = attributes[j],
        attributeName = attribute.localName,
        attributeValue = attribute.value
          // eslint-disable-next-line no-control-regex
          .replaceAll(/[\u0000-\u0020\u00A0\u1680\u180E\u2000-\u2029\u205F\u3000]/g, "")
          .toLowerCase()
          .trim();

      // Remove insecure attribute starting "on*" (example: <img src='' onerror=alert(1)>)
      if (attributeName.indexOf("on") == 0) element.removeAttribute(attributeName);
      // Remove insecure src/href attribute with value starting "javascript:*" (example: href="javascript:alert(1)")
      else if ((attributeName == "src" || attributeName == "href") && attributeValue.indexOf("javascript:") == 0)
        element.removeAttribute(attributeName);
      /**
       * For non-specific tags remove insecure src/data attribute with value starting
       * "data:*" (example: <embed src="data:text/html;base64,PHNjcmlwdD5hbGVydCgxKTwvc2NyaXB0Pg==">)
       */ else if (
        !["audio", "image", "img", "source", "video"].includes(tagName) &&
        (attributeName == "src" || attributeName == "data") &&
        attributeValue.indexOf("data:") == 0
      )
        element.removeAttribute(attributeName);
    }
  }

  return div.innerHTML;
};

export const cleanupAIGeneratedCode = (generatedCode: string, language?: string | null) => {
  if (!language || language === "plaintext") {
    const regex = /<code lang=".*?">/g;
    const regexAlt = /<code class="language-.*?">/g;

    return generatedCode.replaceAll(regex, "").replaceAll(regexAlt, "").replaceAll(regexAlt, "").replaceAll(`</code>`, "");
  }

  return generatedCode
    .replaceAll(`<code lang="${language}">`, "")
    .replaceAll(`<code class="language-${language}">`, "")
    .replaceAll(`<code lang="${language.toLowerCase()}">`, "")
    .replaceAll(`<code class="language-${language.toLowerCase()}">`, "")
    .replaceAll(`</code>`, "");
};

export const nl2lb = (text?: string) => {
  if (!text) {
    return "";
  }

  return text.replaceAll(`\n`, "<br>");
};

export const formatAIGeneratedHTML = (generatedHTML?: string) => {
  if (generatedHTML) {
    generatedHTML = marked.parse(sanitizeMarkdown(generatedHTML));
  }

  return safeHTML(generatedHTML || "");
};

export const stripEmojis = (text: string) => {
  return text.replace(emojiRegex(), "");
};

export const prepareResponseForTTS = (text: string, replacement?: string) => {
  let sanitizedText = sanitizeMarkdown(text);

  if (!replacement) {
    replacement = getRandomElement<string>(CODE_REPLACEMENT_SENTENCES);
  }

  sanitizedText = sanitizedText.replaceAll(/```[\S\s]*?```/g, replacement);

  return stripEmojis(markdownToTxt(sanitizedText));
};

export const formatDuration = (seconds: number) => {
  return new Date(1000 * seconds).toISOString().slice(14, 19);
};

export const getRandomElement = <T = unknown>(arr: Array<T>) => {
  return arr[Math.floor(Math.random() * arr.length)] ?? arr[0];
};
