/* eslint-disable max-len */
export const FEATURES_INFO = [
  {
    title: "Adaptive Learning",
    description: "Instant feedback on user performance, rewards and progressive challenges that inspire learners to improve their skills.",
    icon: "notebook",
    color: "#8C46F3",
    className: "home-purple",
    iconClass: "descendant:text-home-purple",
  },
  {
    title: "Gamification",
    description: "We use generative AI tools to customize learning materials to prevent overwhelming learners while providing ideal challenges.",
    icon: "game-pad",
    color: "#FD62A4",
    className: "home-red",
    iconClass: "descendant:text-home-red",
  },
  {
    title: "Personalized Feedback",
    description: "Using generative AI, we offer personalized feedback that highlights strengths and areas to focus on.",
    icon: "feedback",
    color: "#58E0B0",
    className: "home-green",
    iconClass: "descendant:text-home-green",
  },
] as const;

export const INSTRUCTORS_INFO = [
  {
    icon: "recommend",
    title: "Content Suggestion",
    text: "Gain insights from learner's challenges to generate content",
  },
  {
    icon: "edit",
    title: "Educate Your Own Way",
    text: "Share knowledge with your own flair and ignite a love for learning",
  },
  {
    icon: "edit",
    title: "Inspire Learners",
    text: "Fuel the desire for knowledge and growth",
  },
] as const;

export const LEARNERS_INFO = [
  {
    icon: "recommend",
    title: "Immediate Feedback",
    text: "You would be able to get reviews on content based on viewer's assessment",
  },
  {
    icon: "edit",
    title: "Personalized & Adaptive",
    text: "Learnerverse creates a customized experienced for each individual learner.",
  },
] as const;

export const FAQ = [
  {
    question: "What is Learnerverse?",
    answer: {
      text: "Learnerverse is a cutting-edge online learning platform that leverages artificial intelligence (AI) to enhance, customize, and gamify the user learning journey. Learnerverse helps users achieve their learning goals through continuous feedback and improvement.",
      list: [],
    },
  },
  {
    question: "How do I teach on Learnerverse?",
    answer: {
      text: "Learnerverse allows you to create course content in various formats, such as text, audio, or media. You can also use our tools to help you design and deliver your course effectively.",
      list: [],
    },
  },
  {
    question: "Who can teach on Learnerverse?",
    answer: {
      text: "Learnerverse welcomes anyone who has valuable knowledge to share to become a teacher on our platform. We believe that we can all teach and learn from each other. However, we also ensure that our premium courses meet high standards before publication.",
      list: [],
    },
  },
  {
    question: "What are the incentives for course creators?",
    answer: {
      text: "Course creators have two ways to earn income:",
      list: [
        "They can create premium courses and receive a generous percentage of the purchase fee.",
        "They can also earn a share of the ad revenue, depending on how much their content attracts and retains users.",
      ],
    },
  },
  {
    question: "Do I need a subscription to learn?",
    answer: {
      text: "No. You can learn without a subscription, as all non-premium content is free for everyone. However, you need to register to use some of the AI tools, and you need a subscription to unlock all the tools and features.",
      list: [],
    },
  },
  {
    question: "What are the benefits of a subscription?",
    answer: {
      text: "As a subscribed user, you can benefit from a distraction-free learning environment without ads and Complete access to the AI tools, assessment reports, and personalized suggestions.",
      list: [],
    },
  },
  {
    question: "How is Learnerverse different from other learning platforms?",
    answer: {
      text: "Learnerverse distinguishes itself by:",
      list: [
        "Emphasizing continuous improvement rather than grades.",
        "Providing innovative tools that enhance the user learning experience.",
        "Offering assessments to measure user understanding and suggest areas of improvement.",
        "Delivering detailed reports on progress based on cumulative assessment results.",
      ],
    },
  },
] as const;

export const CREATORS_CATEGORY_INFO = [
  {
    icon: "money-bag-filled",
    title: "Small Business Owners",
    text: "Teach what made your business grow",
  },
  {
    icon: "pulse-bag-filled",
    title: "Fitness Trainers",
    text: "Sell workout plans and health tips",
  },
  {
    icon: "wand",
    title: "Artists & Creators",
    text: "Share your creative process and techniques",
  },
  {
    icon: "cloud-storage-filled",
    title: "Tech Enthusiasts",
    text: "Teach software development, programming or cyber security.",
  },
  {
    icon: "idea-bulb-filled",
    title: "Anyone With a Hobby",
    text: "From cooking, photography to gardening, your passion can pay off",
  },
] as const;

export const CREATORS_ONBOARDING_INFO = [
  {
    heading: "Sign Up For Free",
    text: "Create an account in seconds and start exploring",
  },
  {
    heading: "Build your Course",
    text: "Use LearnerVerse’s tools to create content you’re proud of",
  },
  {
    heading: "Launch and Start Earning",
    text: "Set your price, publish your course, and connect with learners",
  },
] as const;

export const TOP_CREATOR_TOOLS = [
  "Generate professional course outlines in minutes",
  "Design stunning thumbnails with zero design experience",
  "Upload content your way—whether it’s text, videos, or both",
];

export const CREATOR_FEATURE_OPTIONS = [
  {
    icon: "closed-book",
    title: "Online Courses",
    text: "Share your expertise through video or text-based lessons and start earning",
  },
  {
    icon: "graduate-cap",
    title: "Coaching Services",
    text: "Connect personally with your audience by offering one-on-one coaching",
  },
  {
    icon: "user-card",
    title: "Membership Plans",
    text: "Turn your followers into loyal customers with subscription-based content",
  },
  {
    icon: "user-community",
    title: "Community Spaces",
    text: "Engage and grow with your students in a private, dedicated space",
  },
] as const;

export const LEARNERS_BENEFIT_OPTIONS = [
  {
    icon: "award-badge-filled",
    title: "Earn Engagement Points",
    text: "Early sign-ups receive special points to unlock AI-powered learning tools that simplify your journey. Start earning engagement points for signing up, completing lessons, or participating in challenges. Use these points to unlock:",
    options: [
      {
        icon: "ai-mentor-filled",
        title: "AI Mentors",
        text: "Get instant help from an always-available tutor. Ask questions, request summaries, and get personalized support on course materials.",
      },
      {
        icon: "diamond-filled",
        title: "Free Premium Courses",
        text: "Access special courses at no extra cost to boost your skills.",
      },
      {
        icon: "game-pad-filled",
        title: "Interactive Games",
        text: "Use your points to unlock fun, educational games that make learning even more exciting.",
      },
      {
        icon: "ai-cog",
        title: "More Tools Coming Soon",
        text: "As we grow, your points will help you explore new features like our upcoming Study Hub for managing your learning materials.",
      },
    ],
  },
  {
    icon: "chart-filled",
    title: "Build a Streak and Earn More Points",
    text: "The more consistent you are, the more rewards you get! Keep your learning streak alive and earn bonus points as you go",
    options: [],
  },
];

export const LEARNERS_HIGHLIGHT_FEATURES = [
  {
    heading: "Immediate Feedback",
    text: "Get reviews on your progress and content based on viewer assessments, so you always know where you stand and how to improve.",
  },
  {
    heading: "Real-Time Adjustments",
    text: "Our system learns with you, adapting challenges and recommendations to match your growth.",
  },
  {
    heading: "Skill Development",
    text: "From learning essential skills to mastering advanced concepts, you'll gain knowledge that translates directly into the real world.",
  },
] as const;

export const TOP_LEARNER_TOOLS = [
  {
    heading: "Instant Feedback",
    text: "See how you're doing right away with performance reviews and viewer assessments.",
  },
  {
    heading: "Progressive Challenges",
    text: "Stay motivated with tasks that grow with your skill level",
  },
  {
    heading: "Rewards for Progress",
    text: "Earn points and badges as you improve",
  },
] as const;

export const LEARNERS_FEATURE_OPTIONS = [
  {
    icon: "chat",
    title: "Ask Questions",
    text: '"What’s the capital of Ghana?" or "Explain fractions simply."',
  },
  {
    icon: "article-content",
    title: "Request Summaries",
    text: '"Summarize this chapter for me in three points."',
  },
  {
    icon: "game-pad",
    title: "Play Games",
    text: "Test your knowledge with quizzes that challenge and teach",
  },
  {
    icon: "badge",
    title: "Build Confidence",
    text: "Get instant feedback and encouragement to keep going.",
  },
] as const;
