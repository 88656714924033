import type { LearningType } from "~/utils/types";

export const LEARNING_TYPE = {
  Visual: "visual",
  Logical: "logical",
  Auditory: "auditory",
  GameBased: "game-based",
} as const;

export const hasGameMode = (type: LearningType) => {
  return type === LEARNING_TYPE.GameBased;
};
