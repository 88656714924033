import type { ApiErrorResponse, FetchOptions } from "~/utils/types";
import type { FetchError } from "ofetch";
import { COOKIE_KEYS } from "~/constants";

export const customFetch = async <TData, TBody = undefined>(url: string, options?: FetchOptions<TBody>) => {
  let data: TData | null = null;
  let error: FetchError<ApiErrorResponse<TBody>> | null = null;

  try {
    const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

    data = await $fetch<TData>(url, {
      method: options?.method || "GET",
      baseURL: options?.baseURL || (useRuntimeConfig().public.apiBaseUrl as string),
      headers: options?.headers || getApiRequestHeaders(token.value),
      query: options?.query,
      body: options?.body as RequestInit["body"] | Record<string, unknown>,
      credentials: "omit",
    });
  } catch (error_) {
    data = null;
    error = error_ as FetchError<ApiErrorResponse<TBody>>;
  }

  return {
    data,
    error,
  };
};

export const customServerFetch = async <TData, TBody = undefined>(url: string, options?: FetchOptions<TBody>) => {
  let data: TData | null = null;
  let error: FetchError<ApiErrorResponse<TBody>> | null = null;

  try {
    const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

    data = await $fetch<TData>(url, {
      method: options?.method || "GET",
      baseURL: options?.baseURL || (useRuntimeConfig().public.siteUrl as string),
      headers: options?.headers || getApiRequestHeaders(token.value),
      query: options?.query,
      body: options?.body as RequestInit["body"] | Record<string, unknown> | undefined,
    });
  } catch (error_) {
    error = error_ as FetchError<ApiErrorResponse<TBody>>;
  }

  return {
    data,
    error,
  };
};
