/* eslint-disable max-len */
import type { StudyApproachType, StudyMaterialType, StudyMotivationType, StudySessionStatusType } from "~/utils/types";

export const MATERIAL_SOURCES = {
  Audio: "audio",
  Book: "book",
  Course: "course",
  Link: "link",
  Transcript: "transcript",
  Video: "video",
} as const;

export const STUDY_MOTIVATION_CATEGORY = {
  Instrumental: 1,
  Intrinsic: 2,
} as const;

export const STUDY_SESSION_STATUS = {
  Created: "created",
  Setup: "setup",
  Ready: "ready",
  Studying: "studying",
  Inactive: "inactive",
  Overdue: "overdue",
  Canceled: "canceled",
  Completed: "completed",
} as const;

export const STUDY_DEPTH = {
  Deep: "deep",
  Shallow: "shallow",
  Wide: "wide",
  Research: "research",
} as const;

export const STUDY_ENV = {
  QuietSpace: "Quiet Space",
  ComfortableSetup: "Comfortable Chair & Desk Setup",
  NaturalLight: "Natural Lighting",
  MinimalDistractions: "Minimal Distractions",
  SoloStudy: "Solo Study Sessions",
  GroupStudy: "Group Study Sessions",
  ActiveLearning: "Active Learning",
  Dynamic: "Dynamic Environment",
  FrequentBreaks: "Frequent Breaks",
} as const;

export const STUDY_APPROACH = {
  IntensePractice: "practice",
  ProjectBased: "project",
  Simulation: "simulation",
  Immersive: "immersive",
  Overkill: "overkill",
} as const;

export const STUDY_MATERIAL_TYPE = {
  Guide: "guide",
  Primary: "primary",
  Prerequisite: "prerequisite",
  Research: "research",
  Secondary: "secondary",
  Drill: "drill",
} as const;

export const STUDY_MATERIAL_PROVIDER = {
  Cloudinary: "cloudinary",
  Youtube: "youtube",
  ResourceUrl: "resource_url",
  S3: "s3",
  TextContent: "text_content",
} as const;

export const QUEST_INFO_ID = 1;
export const SKILL_INFO_ID = 2;
export const QUEST_PREF_ID = 3;

export const FORM_STEPS = [
  {
    id: 0,
    name: "Quest Intro",
    heading: "Hello Adventurer! Welcome to Study Quests!",
    description:
      "Unleash your inner knowledge warrior with this tool! It’s crafted to help you conquer any material you throw at it, and master it at lightning speed. Ready to embark on your learning adventure?",
  },
  {
    id: QUEST_INFO_ID,
    name: "Quest Info",
    heading: "Quest Information",
    description: "Tell us what you want to study, and how familiar you are with the concepts",
  },
  {
    id: SKILL_INFO_ID,
    name: "Skills Info",
    heading: "Skills & Related Skills Information",
    description:
      "Share with us the specific skills or concepts you’re eager to dive into from the material you’ll provide. Plus, let us know any related skills you’ve already mastered. Ready to level up your learning game?",
  },
  {
    id: QUEST_PREF_ID,
    name: "Quest Preferences",
    heading: "Quest Preferences",
    description:
      "Share with us your reasons and approaches to studying, along with the ideal environment that fuels your learning. Let’s craft a truly personalized learning journey just for you!",
  },
];

export const STUDY_SESSION_SECTIONS_CATEGORY = {
  Overview: "overview",
  Benchmarking: "benchmarking",
  Content: "content",
} as const;

export const SECTION_INTERACTIONS = {
  EmphasizeExclude: "emphasize-exclude",
} as const;

export const STUDY_SESSION_SECTIONS = [
  {
    id: "overview.outline",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Overview,
    name: "outline",
    interaction: SECTION_INTERACTIONS.EmphasizeExclude,
    interactionMsg: "Select topics you want to highlight or exclude by clicking 'Update Roadmap'",
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "overview.importance",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Overview,
    name: "importance",
    canAskQuestions: true,
    canSkip: true,
  },
  {
    id: "overview.advice",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Overview,
    name: "advice",
    canAskQuestions: true,
    canSkip: true,
  },
  {
    id: "benchmarking.concepts",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Benchmarking,
    name: "concepts",
    canAskQuestions: true,
    canSkip: true,
  },
  {
    id: "benchmarking.facts",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Benchmarking,
    name: "facts",
    canAskQuestions: true,
    canSkip: true,
  },
  {
    id: "benchmarking.procedures",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Benchmarking,
    name: "procedures",
    canAskQuestions: true,
    canSkip: true,
  },
  {
    id: "content.*",
    category: STUDY_SESSION_SECTIONS_CATEGORY.Content,
    name: "content",
    canAskQuestions: true,
    canSkip: false,
  },
] as const;

export const STUDY_SESSION_INTRO = [
  {
    title: "Welcome, DISPLAY_NAME, to the Epic Quest of Mastering STUDY_SUBJECT!",
    desc:
      "Greetings, brave adventurer! Today, you embark on an exciting journey through the mystical lands of STUDY_SUBJECT. Your objective, is to conquer challenges, unlock hidden knowledge, and level up your skills.\n" +
      "\n" +
      "As you step into this realm, remember that every challenge you face is a step closer to becoming a master of STUDY_SUBJECT. Are you ready to begin your quest and become a legend in the world of STUDY_SUBJECT? Let the adventure begin! 🚀.",
  },
  {
    title: "Welcome, DISPLAY_NAME, to the STUDY_SUBJECT Adventure Arena!",
    desc:
      "Hey there, DISPLAY_NAME! Get ready to dive into the Adventure Arena, where your quest to master STUDY_SUBJECT begins. Each challenge you face will be a thrilling test of your skills and knowledge.\n" +
      "\n" +
      "Every step you take brings you closer to becoming a STUDY_SUBJECT champion. So, gear up, stay focused, and let the adventure unfold! Your epic journey starts now!",
  },
  {
    title: "Greetings, DISPLAY_NAME, to the STUDY_SUBJECT Study Quest!",
    desc:
      "Hello, DISPLAY_NAME! Welcome to the Study Quest, where your mission to conquer STUDY_SUBJECT awaits. This is no ordinary study session—it’s a thrilling adventure where every task completed and every problem solved brings you closer to victory.\n" +
      "\n" +
      "Are you ready to take on the challenge and emerge victorious? Let’s get started!",
  },
];

export const STUDY_OVERVIEW_NAMES = {
  Outline: "outline",
  Advice: "advice",
  Importance: "importance",
} as const;

export const DEFAULT_STUDY_MOTIVATION = "I'm very curious about this topic and want to understand";

export const formattedStudyMotivationCategory = () => {
  return [
    {
      name: "Studying to achieve a specific outcome",
      value: STUDY_MOTIVATION_CATEGORY.Instrumental,
    },
    {
      name: "Studying out of curiosity or without a specific outcome",
      value: STUDY_MOTIVATION_CATEGORY.Intrinsic,
    },
  ];
};

export const getFormattedStudyMotivationCategory = (motivation?: StudyMotivationType) => {
  if (!motivation) {
    return "";
  }

  const category = formattedStudyMotivationCategory();

  return category.find((cat) => cat.value === motivation).name;
};

export const isEditableStudySession = (status: StudySessionStatusType) => {
  return status === STUDY_SESSION_STATUS.Created || status === STUDY_SESSION_STATUS.Setup;
};

export const isStudySessionReadyOrInProgress = (status: StudySessionStatusType) => {
  return status === STUDY_SESSION_STATUS.Ready || status === STUDY_SESSION_STATUS.Studying || status === STUDY_SESSION_STATUS.Overdue;
};

export const materialTypeDescription = (type: StudyMaterialType) => {
  switch (type) {
    case STUDY_MATERIAL_TYPE.Primary: {
      return "The main content to be studied. Most concepts discussed will originate from here";
    }

    case STUDY_MATERIAL_TYPE.Secondary: {
      return "A material that elaborate on concepts, or provide extra necessary information inline with the main content";
    }

    case STUDY_MATERIAL_TYPE.Prerequisite: {
      return "A material containing prerequisite knowledge necessary to understand the main content";
    }

    case STUDY_MATERIAL_TYPE.Guide: {
      return "A study guide that dictates the generated roadmap and study structure";
    }

    case STUDY_MATERIAL_TYPE.Research: {
      return "Research paper(s) related to concepts discussed in the main content";
    }

    case STUDY_MATERIAL_TYPE.Drill: {
      return "Material(s) focused on specific areas of the main content that seeks to tackle you weaknesses";
    }
  }
};

export const studyApproachDescription = (type: StudyApproachType) => {
  switch (type) {
    case STUDY_APPROACH.IntensePractice: {
      return {
        desc: "Continuous, and intense practice of the concepts discussed in the study materials",
        prompt: "The learner favours intense practice while studying, so you must take this into consideration while generating your responses.",
      };
    }

    case STUDY_APPROACH.Overkill: {
      return {
        desc: "Aiming higher than your desired goal by increasing the difficulty, and if it's too challenging, gradually reduce the difficulty",
        prompt: "The learner loves a challenge, so start with difficult concepts and skills while generating responses.",
      };
    }

    case STUDY_APPROACH.ProjectBased: {
      return {
        desc: "Learn by working on practical projects",
        prompt: "The learner loves project-based learning and so include projects that can be worked on whenever possible.",
      };
    }

    case STUDY_APPROACH.Simulation: {
      return {
        desc: "For skills that can't be practiced, a simulated environment to mimic the actual environment for the purpose of learning.",
        prompt:
          "The learner prefers simulation method while learning in the event they cannot directly practice the skill, in this case, a simulated environment to mimic the actual environment for the purpose of learning is required whenever possible.",
      };
    }

    case STUDY_APPROACH.Immersive: {
      return {
        desc: "Practice much more than required and subject yourself to situations that require your to learn the skill quickly",
        prompt:
          "The learner really wants an immersive learning experience, this means more practice than required should be included whenever possible, and the learner should be put in scenarios where they have to quickly learn to proceed.",
      };
    }

    default: {
      throw new Error("Unknown approach");
    }
  }
};

export const STUDY_BENCHMARKS_SECTIONS = {
  Concepts: "concepts",
  Facts: "facts",
  Procedures: "procedures",
} as const;
