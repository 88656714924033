export const CLIENT_URLS = {
  LandingPage: "/",
  Home: "/",
  Privacy: "/privacy",
  TOS: "/terms-of-service",

  CreatorsGuide: "/guides/creators",
  LearnersGuide: "/guides/learners",

  Feed: "/feed",
  SignUp: "/new-journey",
  SignIn: "/continue-journey",
  ProfileSetup: "/setup-profile",
  RequestPasswordReset: "/password/request-reset",

  CoursesIndex: "/courses",
  CourseCategories: "/courses/categories",
  CourseConcepts: "/courses/concepts",
  CoursesAnalytics: "/courses/analytics",
  CoursesAnalyticsCourses: "/courses/analytics/courses",
  CourseDetail: "/courses/{slug}",
  CourseContent: "/courses/content/{slug}",
  CourseChallenges: "/courses/challenges/{slug}?type={type}",
  CoursesAnalyticsDetail: "/courses/analytics/courses/{slug}",

  CreateMentor: "/mentors/create",
  Mentors: "/mentors",
  EditMentor: "/mentors/{mentor}",

  PerformanceReviews: "/performance-reviews",
  PerformanceReviewDetail: "/performance-reviews/challenge-{id}",
  CoursePerformanceReviews: "/performance-reviews/courses",
  CoursePerformanceReviewDetail: "/performance-reviews/courses/{slug}",

  CreateNote: "/notes/create",
  Notes: "/notes",
  NoteDetails: "/notes/{slug}",
  Profile: "/profile/{username}",
  Account: "/account",

  ContentSuite: "/content-suite",
  CreateCourse: "/content-suite/create",
  EditCourse: "/content-suite/manage-course/{slug}/edit",
  ManageCourse: "/content-suite/manage-course/{slug}",
  CreateCourseContent: "/content-suite/manage-course/{slug}/create-content",
  EditCourseContent: "/content-suite/manage-course/{slug}/edit-content",

  StudyQuestIndex: "/study-quests",
  CreateStudyQuest: "/study-quests/new-quest",
  StudyQuestDetails: "/study-quests/{slug}",
  EditStudyQuest: "/study-quests/{slug}/edit",
  StudyQuestStudyPage: "/study-quests/{slug}/study",
  CreateStudyMaterial: "/study-quests/{slug}/create-material",

  Watchlist: "/library/watchlist",
  CoursesInProgress: "/library/ongoing-courses",
  CompletedCourses: "/library/completed-courses",

  PointsStore: "/store/engagement-points",
} as const;

export const CLIENT_API_URLS = {
  YoutubeTranscript: "/api/transcript/youtube/{slug}",
} as const;
