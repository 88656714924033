<template>
  <div class="dark">
    <slot></slot>

    <div id="modals" class="shadow-home-purple dark:text-theme-light sm:shadow-home-red md:shadow-home-green"></div>
  </div>
</template>

<script setup lang="ts">
useHeadSafe({
  meta: [
    {
      name: "color-scheme",
      content: "dark",
    },
  ],
});
</script>
