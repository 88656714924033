<template>
  <div class="flex min-h-screen w-full" :class="{ dark: isDark }">
    <div class="flex w-full flex-col gap-4 pt-6 lg:flex-row lg:justify-center lg:gap-6" :class="background">
      <div class="flex w-full justify-start justify-center lg:w-1/2 lg:items-center xl:w-1/3">
        <img
          v-if="Number(error.statusCode) === RESPONSE_STATUS.NotFound.statusCode"
          :src="ERROR_PAGE_IMAGES.NotFound"
          class="h-auto max-w-xs xsm:max-w-sm md:max-w-md xl:max-w-3xl"
          alt="Error page"
        />
        <img v-else :src="ERROR_PAGE_IMAGES.Error" class="h-auto max-w-xs xsm:max-w-sm md:max-w-md xl:max-w-3xl" alt="Error page" />
      </div>

      <div class="flex w-full flex-col justify-center gap-2 pt-20 lg:w-1/2 lg:items-center xl:w-1/3">
        <div class="flex flex-col gap-2 lg:gap-4 xl:gap-2">
          <h1 class="text-center text-6xl font-bold text-metallic-violet dark:text-gray-100 lg:text-left lg:text-[7rem] xl:text-[10rem]">
            {{ error.statusCode || "Oops!" }}
          </h1>

          <p class="mb-4 text-center text-2xl font-medium capitalize text-metallic-violet dark:text-gray-100 lg:text-left xl:text-3xl">
            {{ isProd && (!error.statusCode || Number(error.statusCode) === 500) ? "That was unexpected! We're working on it." : error.message }}
          </p>

          <div class="text-center lg:text-left">
            <primary-button class="py-3 text-xl font-medium" title="Go home" @click="clearError({ redirect: CLIENT_URLS.Feed })"
              >Go Home</primary-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import PrimaryButton from "~/components/Elements/PrimaryButton.vue";
import { useThemeStore } from "~/store/themeStore";
import { CLIENT_URLS, ERROR_PAGE_IMAGES, RESPONSE_STATUS } from "~/constants";

defineProps<{ error: Record<string, unknown> }>();

const store = useThemeStore();

const { switchSidebar, background, isDark, hideFooter } = storeToRefs(store);

const isProd = useRuntimeConfig().public.isProduction;

onMounted(() => {
  switchSidebar.value = false;
  hideFooter.value = false;
});
</script>
