/* eslint-disable max-len */
export const CONTENT_SECTIONS = {
  Objectives: "objectives",
  Introduction: "introduction",
  Foundation: "foundation",
  IndepthStudy: "study",
  Practice: "practice",
  PracticalApplication: "application",
  Summary: "summary",
  NextSteps: "next_steps",
} as const;

export const LESSON_CONTENT_STRUCTURE = {
  Objectives: {
    key: "objectives",
    model: "content",
    field: "objectives",
    action: "update",
    title: "Objectives",
    objective:
      "Help the learner understand the objectives of the lesson, what they are expected to understand or master, as well as what they should be able to do by the end of the lesson",
    steps: [],
  },
  Introduction: {
    key: "introduction",
    model: "content",
    field: "introduction",
    action: "update",
    title: "Introduction and Overview",
    objective: "Help the learner understand the purpose and scope of the content.",
    steps: [
      "Provide the learner with an introduction to the subject or skill to be studies as well as the preface",
      "Provide the learner with the themes and goals.",
    ],
  },
  Foundation: {
    key: "foundation",
    model: "content",
    field: "content",
    action: "append",
    title: "Foundation Knowledge",
    objective: "Help the learner build a strong base of essential concepts, and overall a great understanding of the fundamentals",
    steps: [
      "Provide and help the learner understand basic terminologies.",
      "Generate the fundamental theories or principles, but always take a practical approach, by explaining the application of these theories in the real-world.",
      "Use visual aids (charts, mind maps) for better retention whenever necessary.",
    ],
  },
  IndepthStudy: {
    key: "study",
    model: "content",
    field: "content",
    action: "append",
    title: "In-Depth Study Content",
    objective: "Delve deeper into the lesson's main topics. Emphasize practice and practical applications",
    steps: ["Remember to prioritize practical applications."],
  },
  Practice: {
    key: "practice",
    model: "quiz",
    field: "quiz_question",
    action: "create",
    title: "Practice and Reinforcement",
    objective: "Help the learners reinforce their knowledge through continuous practice.",
    steps: [
      "Generate practice problems for them to answer",
      "Best way to learn is by teaching, encourage the learner to try and teach someone else, or to attempt to teach you. You can do this by creating a scenario where they have to teach a fictional student you create in simulations",
    ],
  },
  PracticalApplication: {
    key: "application",
    model: "task",
    field: "description",
    action: "create",
    title: "Practical Application",
    objective:
      "Generate tasks and projects based on real-world scenarios for the learner that they practice or perform to allow them apply concepts they have learnt.",
    steps: [
      "Use relevant case studies, examples, or practical exercises where applicable",
      "You can generate simulations or role-playing activities that will the learner apply what they have learnt. Do this only if the knowledge that can't directly be practiced (like a pilot) and the have selected the 'simulation' study approach",
      "If the learner has also selected a project based study approach, the your can generate projects for them based on what they have learnt that they can practice. The project should be directly related to what they have learnt.",
    ],
  },
  Summary: {
    key: "summary",
    model: "content",
    field: "summary",
    action: "update",
    title: "Lesson Summary",
    objective: "Generate a summary of the lesson and the concepts the learner has learnt throughout the lesson",
    steps: [],
  },
  NextSteps: {
    key: "next_steps",
    model: "content",
    field: "next_steps",
    action: "update",
    title: "Next Steps",
    objective:
      "Based on the knowledge acquired so far, suggest the next steps the learner should take to deepen their understanding and apply what they've learned? Please provide a detailed plan including advanced readings, relevant projects, and any other activities that would help the learner further enhance their skills and knowledge in this area.",
    steps: [],
  },
} as const;

export const STUDY_CONTENT_SECTIONS = [
  {
    id: "content." + CONTENT_SECTIONS.Objectives,
    category: "content",
    name: CONTENT_SECTIONS.Objectives,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Introduction,
    category: "content",
    name: CONTENT_SECTIONS.Introduction,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Foundation,
    category: "content",
    name: CONTENT_SECTIONS.Foundation,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.IndepthStudy,
    category: "content",
    name: CONTENT_SECTIONS.IndepthStudy,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Practice,
    category: "content",
    name: CONTENT_SECTIONS.Practice,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.PracticalApplication,
    category: "content",
    name: CONTENT_SECTIONS.PracticalApplication,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.Summary,
    category: "content",
    name: CONTENT_SECTIONS.Summary,
    canAskQuestions: true,
    canSkip: false,
  },
  {
    id: "content." + CONTENT_SECTIONS.NextSteps,
    category: "content",
    name: CONTENT_SECTIONS.NextSteps,
    canAskQuestions: true,
    canSkip: false,
  },
] as const;

export const STUDY_CONTENT_TYPE = {
  Activity: "activity",
  Analysis: "analysis",
  Example: "example",
  Explanation: "explanation",
  Game: "game",
  Lecture: "lecture",
  Lesson: "lesson",
} as const;

export const getContentPromptStructureFromSection = (section: string) => {
  switch (section) {
    case CONTENT_SECTIONS.Objectives: {
      return LESSON_CONTENT_STRUCTURE.Objectives;
    }

    case CONTENT_SECTIONS.Introduction: {
      return LESSON_CONTENT_STRUCTURE.Introduction;
    }

    case CONTENT_SECTIONS.Foundation: {
      return LESSON_CONTENT_STRUCTURE.Foundation;
    }

    case CONTENT_SECTIONS.IndepthStudy: {
      return LESSON_CONTENT_STRUCTURE.IndepthStudy;
    }

    case CONTENT_SECTIONS.Practice: {
      return LESSON_CONTENT_STRUCTURE.Practice;
    }

    case CONTENT_SECTIONS.PracticalApplication: {
      return LESSON_CONTENT_STRUCTURE.PracticalApplication;
    }

    case CONTENT_SECTIONS.Summary: {
      return LESSON_CONTENT_STRUCTURE.Summary;
    }

    case CONTENT_SECTIONS.NextSteps: {
      return LESSON_CONTENT_STRUCTURE.NextSteps;
    }

    default: {
      throw new Error("Unrecognized section for section '" + section + "'");
    }
  }
};
