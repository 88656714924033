<template>
  <div class="max-h-full max-w-full overflow-hidden" :data-theme="isDark ? 'dark' : 'light'" :class="{ dark: isDark }">
    <NuxtLoadingIndicator color="#e33e84" />

    <NuxtLayout>
      <NuxtPage />
      <Analytics />
      <SpeedInsights />

      <client-only>
        <dialog id="cookie_consent" class="modal modal-bottom md:modal-middle">
          <div
            class="modal-box mx-auto w-11/12 max-w-3xl lg:scrollbar-thin lg:scrollbar-track-transparent lg:scrollbar-thumb-gray-500 lg:scrollbar-thumb-rounded-full"
            :class="{
              'dark:bg-main-theme-dark-sec dark:scrollbar-thumb-cyan-100/5': isDefaultBg,
              'dark:bg-main-theme-dark-alt-sec dark:scrollbar-thumb-cyan-100/5': isRoyalDarkBg,
              'dark:bg-main-theme-black-sec dark:scrollbar-thumb-gray-100/5': isMidnightBg,
            }"
          >
            <form method="dialog">
              <button tabindex="-1" class="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">✕</button>
            </form>

            <section class="prose max-w-none">
              <h2 class="mb-2 text-lg font-medium">Cookie Consent</h2>
              <p class="mb-2 text-sm">We use cookies to collect information about you. We use this information:</p>
              <ol class="list-decimal text-sm">
                <li>To provide you a personalized experience of our website (functional)</li>
                <li>To collect analytics on your page visits, contents consumed and interactions on our website (statistics)</li>
                <li>To serve you relevant promotions (marketing)</li>
              </ol>

              <p class="text-sm">
                Click “Accept” to give us your consent to use cookies for all these categories. You can also use the checkboxes to consent to specific
                categories. Select categories and “Save Settings”.
              </p>

              <p class="text-sm">
                Withdraw or change your consent at any time by clicking the icon in the bottom left corner of the screen. Change your settings. Read
                more about how we use cookies and other technologies to collect personal data:
                <nuxt-link to="/privacy">Privacy policy</nuxt-link>
              </p>
            </section>

            <section class="my-2 grid grid-cols-2 rounded-xl border border-solid border-gray-400 dark:border-gray-500 sm:grid-cols-4">
              <div class="form-control w-full border-r border-solid border-gray-400 py-2 dark:border-gray-500">
                <label class="flex cursor-pointer flex-col items-center gap-2">
                  <span class="text-sm capitalize">necessary</span>
                  <input type="checkbox" class="toggle toggle-primary toggle-sm" checked disabled />
                </label>
              </div>

              <div class="form-control w-full border-r border-solid border-gray-400 py-2 dark:border-gray-500">
                <label class="flex cursor-pointer flex-col items-center gap-2">
                  <span class="label-text text-sm capitalize">functional</span>
                  <input v-model="cookieControls.functional" type="checkbox" class="toggle toggle-primary toggle-sm" />
                </label>
              </div>

              <div class="form-control w-full border-r border-solid border-gray-400 py-2 dark:border-gray-500">
                <label class="flex cursor-pointer flex-col items-center gap-2">
                  <span class="label-text text-sm capitalize">analytics</span>
                  <input v-model="cookieControls.analytics" type="checkbox" class="toggle toggle-primary toggle-sm" />
                </label>
              </div>

              <div class="form-control w-full">
                <label class="l flex cursor-pointer flex-col items-center gap-2 py-2">
                  <span class="label-text text-sm capitalize">marketing</span>
                  <input v-model="cookieControls.marketing" type="checkbox" class="toggle toggle-primary toggle-sm" />
                </label>
              </div>
            </section>

            <div class="text-right">
              <button class="btn btn-outline btn-xs mb-2" @click="showCookieDetails = !showCookieDetails">
                {{ showCookieDetails ? "Hide" : "Show" }} Details
              </button>
            </div>

            <section v-if="showCookieDetails" class="flex flex-col gap-4 rounded-lg bg-gray-900/5 p-4 dark:bg-cyan-100/5">
              <div v-for="category in cookies" :key="category.id">
                <h3 class="font-medium" :class="{ 'text-metallic-violet': category.enabled }">
                  {{ category.name }}
                </h3>
                <p class="text-sm text-gray-600 dark:text-gray-300">
                  {{ category.description }}
                </p>
              </div>
            </section>

            <div class="modal-action">
              <form method="dialog" class="flex gap-4">
                <button class="btn btn-primary" @click="acceptAll()">Accept</button>
                <button class="btn btn-outline" @click="saveSettings()">Save Settings</button>
              </form>
            </div>
          </div>
        </dialog>

        <button
          v-if="shouldDisplayConsentButton"
          tabindex="-1"
          class="btn btn-circle btn-neutral fixed bottom-2 left-2 z-[30] shadow"
          onclick="cookie_consent.showModal()"
          aria-label="Cookie Consent"
        >
          <nuxt-icon name="cookie" class="text-xl" />
        </button>
      </client-only>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { Analytics } from '@vercel/analytics/nuxt'
import { SpeedInsights } from "@vercel/speed-insights/nuxt";
import { useThemeStore } from "~/store/themeStore";
import { CLIENT_URLS, COOKIE_KEYS } from "~/constants";
import { usePremiumFeatureStore } from "~/store/premiumFeatureStore";
import { getUnixTime } from "date-fns";
import { useAuthStore } from "~/store/authStore";
import type { IUserModel } from "~/models";

provideHeadlessUseId(() => useId());

const showCookieDetails = ref(false);

const store = useThemeStore();
const authStore = useAuthStore();
const featureStore = usePremiumFeatureStore();

const { isDark, isRoyalDarkBg, isDefaultBg, isMidnightBg } = storeToRefs(store);
const { authUser, authUserUpdatedAt, user } = storeToRefs(authStore);

const route = useRoute();

const { cookieControls, cookies, saveSettings, setupCookieSettings } = useCookieConsent();

const colorScheme = computed(() => {
  return {
    name: "color-scheme",
    content: isDark.value ? "dark" : "light",
  };
});

const themeColour = computed(() => {
  return {
    name: "theme-color",
    content: isDark.value ? "#1d232a" : "#fafafa",
  };
});

const isConsentExcludePaths = computed(() => {
  return [CLIENT_URLS.LandingPage, CLIENT_URLS.Privacy, CLIENT_URLS.TOS].includes(route.path);
});

const shouldDisplayConsentButton = computed(() => {
  return [CLIENT_URLS.LandingPage, CLIENT_URLS.Privacy, CLIENT_URLS.TOS, CLIENT_URLS.CreatorsGuide, CLIENT_URLS.LearnersGuide].includes(route.path);
});

useHeadSafe({
  meta: [colorScheme, themeColour],
});

const acceptAll = () => {
  cookieControls.value.functional = true;
  cookieControls.value.analytics = true;
  cookieControls.value.marketing = true;

  saveSettings();
};

const shouldOpenModal = () => {
  if (!shouldDisplayConsentButton.value) {
    return false;
  }

  const cookieConsent = useCookie(COOKIE_KEYS.HideCookieConsent, { readonly: true });

  return cookieConsent.value != "1" && !isConsentExcludePaths.value;
};

const setupMounted = async () => {
  setupCookieSettings();

  if (shouldOpenModal()) {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
    // @ts-ignore
    document.querySelector("#cookie_consent")?.showModal();
  }

  await featureStore.getFeatureList(false);
};

watch(user, (newUser: IUserModel) => {
  if (!newUser) {
    return;
  }

  authUser.value = {
    id: newUser.id,
    mentor_id: newUser.mentor_id,
    first_name: newUser.first_name,
    middle_name: newUser.middle_name,
    last_name: newUser.last_name,
    username: newUser.username,
    display_name: newUser.display_name,
    email: newUser.email,
    role: newUser.role,
    avatar: newUser.avatar,
    experience_points: newUser.experience_points,
    engagement_points: newUser.engagement_points,
    level: newUser.level,
  };

  authUserUpdatedAt.value = getUnixTime(new Date());
});

onMounted(async () => {
  await nextTick();

  await setupMounted();
});
</script>
