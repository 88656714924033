import { usePreferredDark } from "@vueuse/core";
import { APP_THEME } from "~/constants";
import { updateAccount } from "~/services/account.service";
import type { AppThemeType, DrawerPositionType } from "~/utils/types";
import type { IBadgeModel } from "~/models";

export const useThemeStore = defineStore(
  "theme",
  () => {
    const theme = ref<AppThemeType | undefined | null>(APP_THEME.System);
    const isDark = ref(true);
    const lightBg = ref("bg-main-theme-light");
    const darkBg = ref("bg-main-theme-dark");
    const isNotificationForceOpened = ref(false);
    const hideLabels = ref(true);
    const openSidebar = ref(false);
    const switchSidebar = ref(false);
    const hideFooter = ref(false);
    const closeMobileSidebar = ref(false);
    const enableThemeToggler = ref(true);
    const editorButtons = ref({
      bubble: true,
      floating: false,
      top: true,
      bottom: false,
    });
    const editableEditor = ref(true);
    const disableMenu = ref(false);
    const simpleMenu = ref(false);
    const codeEditorValue = ref<string>("");

    const isDrawerOpen = ref(false);
    const drawerSide = ref<DrawerPositionType>("right");

    const authModalMsg = ref("Sign in to react to the content");

    const badgeDetail = shallowRef<{
      unlocked: boolean;
      badge: IBadgeModel;
    }>();

    const secDarkBg = computed(() => {
      let dark = "bg-main-theme-dark-sec";

      if (isMidnightBg.value) {
        dark = "bg-main-theme-black-sec";
      } else if (isRoyalDarkBg.value) {
        dark = "bg-main-theme-dark-alt-sec";
      }

      return dark;
    });

    const secBackground = computed(() => `bg-gray-200 dark:${secDarkBg.value}`);

    const background = computed(() => `${lightBg.value} dark:${darkBg.value}`);

    const themeConfig = computed(() => {
      return [
        {
          id: "dark",
          name: "Dark",
          label: "Dark mode",
          theme: APP_THEME.Dark,
          applyTheme: () => {
            setTheme("dark");
            setDarkBg("bg-main-theme-dark");
          },
          iconClass: "dark:descendant:text-theme-blue",
          icon: "nav/moon",
          activeIcon: "nav/moon-filled",
          isActive: () => theme.value === APP_THEME.Dark && isDefaultBg.value,
        },
        {
          id: "blue",
          name: "Royal Blue (Dark)",
          label: "Dark mode",
          theme: APP_THEME.Dark,
          applyTheme: () => {
            setTheme("dark");
            setDarkBg("bg-main-theme-dark-alt");
          },
          iconClass: "dark:descendant:text-theme-blue",
          icon: "nav/moon",
          activeIcon: "nav/moon-filled",
          isActive: () => theme.value === APP_THEME.Dark && isRoyalDarkBg.value,
        },
        {
          id: "black",
          name: "Midnight (Dark)",
          label: "Dark mode",
          theme: APP_THEME.Dark,
          applyTheme: () => {
            setTheme("dark");
            setDarkBg("bg-main-theme-black");
          },
          iconClass: "dark:descendant:text-theme-blue",
          icon: "nav/moon",
          activeIcon: "nav/moon-filled",
          isActive: () => theme.value === APP_THEME.Dark && isMidnightBg.value,
        },
        {
          id: "light",
          name: "Light",
          label: "Light mode",
          theme: APP_THEME.Light,
          applyTheme: () => {
            setTheme("light");
          },
          iconClass: "descendant:text-theme-pink",
          icon: "nav/sun",
          activeIcon: "nav/sun-filled",
          isActive: () => theme.value === APP_THEME.Light,
        },
        {
          id: "system",
          name: "System Preferred",
          label: "System Default",
          theme: APP_THEME.System,
          applyTheme: () => {
            setTheme("system_default");
            setDarkBg("bg-main-theme-dark");
          },
          iconClass: "descendant:text-theme-pink dark:descendant:text-theme-blue",
          icon: "nav/laptop",
          activeIcon: "nav/laptop-filled",
          isActive: () => theme.value === APP_THEME.System,
        },
      ];
    });

    const isDefaultBg = computed(() => background.value === "bg-main-theme-light dark:bg-main-theme-dark");

    const isRoyalDarkBg = computed(() => darkBg.value === "bg-main-theme-dark-alt");

    const isMidnightBg = computed(() => darkBg.value === "bg-main-theme-black");

    const loadConfig = (userTheme?: AppThemeType | null) => {
      theme.value = userTheme;

      switch (theme.value) {
        case APP_THEME.Dark: {
          isDark.value = true;
          break;
        }

        case APP_THEME.Light: {
          isDark.value = false;
          break;
        }

        default: {
          isDark.value = usePreferredDark().value;
          theme.value = APP_THEME.System;
          break;
        }
      }
    };

    const setTheme = async (appTheme: AppThemeType) => {
      theme.value = appTheme;

      loadConfig(appTheme);

      await updateAccount({
        profile: { theme: theme.value },
      });
    };

    const setDarkBg = (bg: string) => (darkBg.value = bg);

    const setLightBg = (bg: string) => (lightBg.value = bg);

    const toggleSidebar = () => (openSidebar.value = !openSidebar.value);

    const toggleRequestAuthModal = (message?: string) => {
      if (message) {
        authModalMsg.value = message;
      }

      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
      // @ts-ignore
      document.querySelector("#request_auth")?.showModal();
    };

    const triggerCodeEditor = useThrottleFn(() => {
      if (editableEditor.value) {
        return;
      }

      for (const button of document.querySelectorAll("button.code-editor-button")) {
        button.addEventListener("click", () => {
          const parent = button.parentElement?.parentElement;

          if (!parent) {
            return;
          }

          codeEditorValue.value = parent.querySelector("code")?.textContent || "";

          document.querySelector("#code_editor")?.showModal();
        });
      }
    }, 2000);

    const addEditorButton = useThrottleFn(() => {
      if (editableEditor.value) {
        return;
      }

      const codeBlocks = document.querySelectorAll(".ProseMirror pre code");

      for (const block of codeBlocks) {
        if (!block.querySelector("button.code-editor-button")) {
          const div = document.createElement("div");
          div.classList.add("flex");
          div.classList.add("justify-end");
          div.classList.add("py-1");

          const button = document.createElement("button");
          button.classList.add("code-editor-button");
          button.classList.add("rounded-md");
          button.classList.add("bg-metallic-violet");
          button.classList.add("px-4");
          button.classList.add("py-1");
          button.classList.add("text-white");
          button.classList.add("focus:outline-none");
          button.textContent = "Interact";

          div.append(button);

          block.parentElement?.append(div);
        }
      }
    }, 2000);

    return {
      isDark,
      theme,
      lightBg,
      darkBg,
      secDarkBg,
      hideLabels,
      openSidebar,
      editorButtons,
      editableEditor,
      authModalMsg,
      enableThemeToggler,
      switchSidebar,
      hideFooter,
      closeMobileSidebar,
      disableMenu,
      simpleMenu,
      isNotificationForceOpened,
      codeEditorValue,
      badgeDetail,
      background,
      secBackground,
      themeConfig,
      isDefaultBg,
      isDrawerOpen,
      drawerSide,
      isRoyalDarkBg,
      isMidnightBg,
      setTheme,
      loadConfig,
      setDarkBg,
      setLightBg,
      toggleSidebar,
      toggleRequestAuthModal,
      triggerCodeEditor,
      addEditorButton,
    };
  },
  {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/
    // @ts-ignore
    persist: {
      pick: [
        "isDark",
        "theme",
        "lightBg",
        "darkBg",
        "background",
        "hideLabels",
        "openSidebar",
        "editorButtons",
        "editableEditor",
        "authModalMsg",
        "closeMobileSidebar",
        "switchSidebar",
        "hideFooter",
      ],
    },
  },
);
