<template>
  <main
    v-once
    id="landing-page"
    class="w-full overflow-y-auto overflow-x-hidden bg-[#000312] scrollbar-none selection:bg-metallic-violet selection:text-white dark:text-white"
  >
    <section
      class="relative z-[1] w-screen overflow-hidden bg-[url('/webp/background-image.webp')] bg-cover bg-no-repeat px-4 py-3 shadow-2xl ssm:py-5 md:px-8 xl:px-16 2xl:px-0"
    >
      <TheHeader bordered />

      <section
        class="relative z-[1] mx-auto flex h-[80vh] items-center sm:h-[65vh] xl:mb-20 xl:h-[70vh] 2xl:mx-[120px] 2xl:max-w-[1536px] 2mxl:mx-auto"
      >
        <div class="bg-hero-text-gradient md:max-w-2xl 2xl:max-w-4xl">
          <h1 class="mb-5 text-2xl font-bold leading-[120%] text-white xs:text-4xl md:text-5xl 2xl:text-7xl">
            <span class="gamify">Level Up</span> your skills
            <br />
            with interactive learning & challenges
          </h1>

          <h2 class="mb-5 text-sm font-normal text-gray-300 md:text-lg md:leading-6">
            Learning becomes exciting and engaging on our platform as learners tackle interactive challenges
          </h2>

          <primary-gradient-button @click="navigateTo(CLIENT_URLS.SignUp)">Start Learning</primary-gradient-button>
        </div>
      </section>

      <img
        src="../assets/svg/saturn.svg"
        class="absolute left-1/3 top-1/4 z-0 w-[54px] animate-ufo-bounce select-none sm:w-[116px] md:left-1/2"
        alt="Planet Saturn"
        loading="eager"
      />
      <img
        src="../assets/svg/ufo.svg"
        class="absolute bottom-[5%] left-0 z-0 w-[64px] animate-ufo-bounce select-none lg:w-[127px]"
        alt="Ufo animation"
        loading="eager"
      />
      <img
        src="../assets/svg/rocket.svg"
        class="absolute bottom-0 left-1/3 z-0 hidden w-64 animate-wiggle select-none md:inline-block xl:w-auto"
        alt="Space rocket"
        loading="eager"
      />
      <img
        src="/webp/planet.webp"
        alt="Revolving planet"
        class="absolute right-[-200px] top-[-100px] z-0 w-full max-w-[800px] animate-revolve select-none brightness-[0.8] contrast-150 saturate-50 sm:w-2/3 lg:w-1/2 xl:right-[-50px] xl:top-[-50px] xl:w-[40%]"
        loading="eager"
      />

      <div
        class="star absolute left-1/3 top-0 h-1 w-1 animate-[animate-star_3s_linear_infinite] select-none rounded-full bg-white shadow-star-shadow"
      ></div>

      <div
        class="star absolute left-1/4 top-0 h-1 w-1 animate-[animate-star_2s_linear_0.2s_infinite] select-none rounded-full bg-white shadow-star-shadow"
      ></div>

      <div
        class="star absolute left-[10%] top-0 h-1 w-1 animate-[animate-star_2s_linear_1s_infinite] select-none rounded-full bg-white shadow-star-shadow"
      ></div>

      <div
        class="star absolute left-0 top-[-10px] h-1 w-1 animate-[animate-star_1s_linear_4s_1] select-none rounded-full bg-white shadow-star-shadow"
      ></div>
    </section>

    <section
      class="relative z-[1] mx-auto flex flex-col gap-4 px-4 py-10 ssm:p-6 md:px-8 xl:px-16 2xl:mx-[120px] 2xl:max-w-[1536px] 2xl:px-0 2mxl:mx-auto"
    >
      <Carousel v-bind="settings" :autoplay="4000" wrap-around :breakpoints="breakpoints" :transition="500" pause-autoplay-on-hover class="w-full">
        <Slide v-for="(data, index) in FEATURES_INFO" :key="index">
          <div class="carousel__item mx-4 py-4">
            <div class="cursor-pointer rounded-2xl border border-[#292D4A] px-6 py-8 text-center" :class="`hover:shadow hover-${data.className}`">
              <div class="relative flex items-center justify-center p-12 xl:p-16">
                <div
                  :style="{ boxShadow: `0 0 70px 20px ${data.color}` }"
                  class="absolute left-1/2 top-1/2 flex w-fit justify-center rounded-full"
                ></div>
                <nuxt-icon :name="data.icon" class="text-[40px]" :class="data.iconClass" />
              </div>
              <h2 class="text-2xl">{{ data.title }}</h2>
              <p class="text-base text-[#777A98]">
                {{ data.description }}
              </p>
            </div>
          </div>
        </Slide>
      </Carousel>

      <div class="flex h-24 w-full justify-center pt-4 sm:h-60 md:pt-16">
        <img src="../assets/svg/arrow.svg" class="h-20 animate-ufo-bounce select-none sm:h-fit" alt="bouncing arrow animation" loading="eager" />
        <img src="../assets/svg/square.svg" class="absolute bottom-0 left-0 w-20 select-none sm:w-fit" alt="Smart shapes" loading="eager" />
        <img
          :src="ANIMATION_URLS.PageAnimation"
          class="absolute bottom-0 right-[-100px] z-0 w-40 select-none opacity-30 mix-blend-screen blur-[1px]"
          alt="Spinning animation"
          loading="eager"
        />
        <div
          class="absolute bottom-[-80px] right-[-180px] z-0 h-80 w-80 select-none rounded-full opacity-30 mix-blend-luminosity blur-xl"
          style="background-image: radial-gradient(circle at center, #28487ad9, transparent)"
        ></div>
      </div>
    </section>

    <section class="w-full px-4 md:px-8 xl:px-16 2xl:px-0">
      <div class="relative z-[1] mx-auto 2xl:mx-[120px] 2xl:max-w-[1536px] 2mxl:mx-auto">
        <div
          class="relative z-[1] my-8 flex w-full flex-col-reverse gap-4 rounded-3xl px-4 py-16 md:px-9 lg:flex-row"
          style="background: linear-gradient(260.37deg, #b181f7 4.07%, #0442e3 124.78%)"
        >
          <img
            :src="IMAGE_URLS.CardPattern"
            class="absolute left-0 top-0 z-[1] hidden h-full select-none rounded-3xl lg:inline-block"
            alt="Contour patterns"
          />
          <div class="relative z-[2] flex w-full flex-col gap-6 lg:w-1/2">
            <span class="font-sans text-base font-semibold uppercase tracking-[0.02rem] text-[#5ACEFF] subpixel-antialiased">For Instructors</span>

            <h1
              class="m-0 font-poppins text-2xl font-bold text-white subpixel-antialiased md:text-[2.625rem] md:leading-[3.25rem] md:tracking-[0.0525rem]"
            >
              Share your expertise on Learnerverse
            </h1>

            <h2 class="font-sans text-base font-medium tracking-[0.02rem] text-[#f5f5f5] subpixel-antialiased">
              Help millions of learners reach professional & personal goals
            </h2>

            <ul class="flex flex-col gap-5 subpixel-antialiased">
              <li v-for="(content, index) in INSTRUCTORS_INFO" :key="index">
                <h3 class="flex items-center gap-1 sm:gap-4">
                  <nuxt-icon class="text-2xl" :name="content.icon" />
                  <span class="text-xl font-bold tracking-[0.025rem] text-white">{{ content.title }}</span>
                </h3>
                <p class="pl-7 text-base font-normal leading-[1.5rem] tracking-[0.02rem] text-gray-200 sm:pl-10">
                  {{ content.text }}
                </p>
              </li>
            </ul>
          </div>
          <div class="flex w-full items-center justify-center lg:w-1/2">
            <img src="../assets/svg/dude.svg" class="w-full max-w-[600px]" alt="Graphic of a teacher on a laptop" loading="eager" />
          </div>
        </div>
        <div class="flex h-24 w-full justify-center pt-4 sm:h-60">
          <img src="../assets/svg/arrow.svg" class="h-20 animate-ufo-bounce select-none sm:h-fit" alt="bouncing arrow animation" loading="eager" />
          <img
            :src="IMAGE_URLS.Rocket"
            class="absolute bottom-0 left-[-50px] w-[180px] animate-wiggle select-none sm:left-0 sm:w-fit"
            alt="Rocket"
            loading="eager"
          />
          <img
            :src="IMAGE_URLS.Saturn"
            class="absolute bottom-0 right-[-40px] z-0 w-[84px] animate-wiggle select-none md:right-[-100px] md:w-[168px]"
            alt="Saturn"
            loading="eager"
          />
          <div
            class="absolute bottom-[-80px] right-[-180px] z-0 h-80 w-80 select-none rounded-full opacity-30 mix-blend-luminosity blur-xl"
            style="background-image: radial-gradient(circle at center, #28487ad9, transparent)"
          ></div>
        </div>
      </div>
    </section>

    <section class="w-full px-4 md:px-8 xl:px-16 2xl:px-0">
      <section class="relative z-[1] mx-auto 2xl:mx-[120px] 2xl:max-w-[1536px] 2mxl:mx-auto">
        <div
          class="relative z-[1] my-8 flex w-full flex-col gap-10 rounded-3xl px-4 py-16 md:px-10 lg:flex-row"
          style="background: linear-gradient(221deg, #445cda 0%, #4b0321 100%)"
        >
          <img
            :src="IMAGE_URLS.CardPattern"
            class="absolute right-0 top-0 z-[1] hidden h-full rotate-180 select-none rounded-3xl lg:inline-block"
            alt="Contour patterns"
          />

          <div class="flex w-full items-center justify-center md:px-6 lg:w-1/2">
            <img src="../assets/svg/cpu.svg" class="w-full max-w-[600px]" loading="eager" alt="Graphic of a computer operator" />
          </div>
          <div class="relative z-[2] flex w-full flex-col gap-6 lg:w-1/2">
            <span class="font-sans text-base font-semibold uppercase tracking-[0.02rem] text-[#E33E84] subpixel-antialiased">For Students</span>

            <h1 class="m-0 font-poppins text-[2.625rem] font-bold leading-[3.25rem] tracking-[0.0525rem] text-white subpixel-antialiased">
              Acquire real world skills
            </h1>

            <h2 class="font-sans text-base font-medium tracking-[0.02rem] text-[#f5f5f5] subpixel-antialiased">
              Develop skill sets that equip you for both your present and future aspirations
            </h2>

            <ul class="flex flex-col gap-5 subpixel-antialiased">
              <li v-for="(content, index) in LEARNERS_INFO" :key="index">
                <h3 class="flex items-center gap-1 sm:gap-4">
                  <nuxt-icon class="text-2xl" :name="content.icon" />
                  <span class="text-xl font-bold tracking-[0.025rem] text-white">{{ content.title }}</span>
                </h3>
                <p class="pl-7 text-base font-normal leading-[1.5rem] tracking-[0.02rem] text-gray-200 sm:pl-10">
                  {{ content.text }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full">
          <img
            src="../assets/svg/square.svg"
            class="absolute bottom-[-100px] right-[-100px] w-20 rotate-180 select-none sm:w-fit"
            alt="Smart shapes"
            loading="eager"
          />
          <img
            :src="ANIMATION_URLS.PageAnimation"
            class="absolute bottom-[-80px] left-[-100px] z-0 w-40 select-none opacity-30 mix-blend-screen blur-[1px]"
            alt="Spinning animation"
            loading="eager"
          />
          <div
            class="absolute bottom-[-160px] left-[-180px] z-0 h-80 w-80 select-none rounded-full opacity-30 mix-blend-luminosity blur-xl"
            style="background-image: radial-gradient(circle at center, #28487ad9, transparent)"
          ></div>
        </div>
      </section>
    </section>

    <section class="relative z-[1] mx-auto mt-40 px-4 md:px-8 xl:px-16 2xl:mx-[120px] 2xl:max-w-[1536px] 2xl:px-0 2mxl:mx-auto">
      <h1 class="text-[2.4rem] font-bold text-white">Frequently Asked Questions</h1>

      <div class="w-full py-12">
        <custom-headless-disclosure
          v-for="(question, index) in FAQ"
          :key="index"
          show-caret
          disclosure-class="rounded-xl px-6 py-5 mb-4 border-2 border-solid border-white/[0.1] bg-white/[0.02]"
        >
          <template #heading>
            <span class="text-base font-medium text-white">{{ question.question }}</span>
          </template>
          <template #content>
            <div class="mb-4 w-full rounded-xl bg-white/[0.02] px-6 py-4">
              <div class="prose max-w-none">
                <p class="mb-2 text-gray-300">{{ question.answer.text }}</p>
                <ul v-if="question.answer.list.length > 0" class="list-disc">
                  <li v-for="(item, i) in question.answer.list" :key="i" class="mb-1">
                    {{ item }}
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </custom-headless-disclosure>
      </div>

      <div>
        <h2 class="mb-4 text-2xl font-bold text-white">Still have questions?</h2>

        <p class="mb-6 text-base font-normal text-gray-100">You can get in touch with us by clicking on the button below</p>

        <primary-gradient-link :href="`mailto:${email}`">Get in Touch</primary-gradient-link>
      </div>
    </section>

    <section class="w-full bg-cover px-4 py-20 md:px-8 xl:px-16 2xl:px-0" :style="{ backgroundImage: `url(${bottom})` }">
      <div
        style="background-image: url(/webp/background-image.webp)"
        class="mx-auto rounded-2xl border border-solid border-white/[0.1] bg-cover bg-bottom bg-no-repeat px-4 py-16 text-center md:px-16 2xl:mx-[120px] 2xl:max-w-[1536px] 2mxl:mx-auto"
      >
        <span class="secure text-lg uppercase md:text-2xl">Secure your spot</span>

        <h1 class="m-0 mb-4 mt-8 text-3xl font-bold text-white md:text-[2.75rem]">Ready to dive into a fun learning experience?</h1>

        <p class="mb-4 text-lg text-gray-100 md:text-2xl">Join the waitlist for early access to Learnerverse</p>

        <primary-gradient-button @click="navigateTo(CLIENT_URLS.SignUp)">Start Learning</primary-gradient-button>
      </div>
    </section>

    <TheFooter />
  </main>
</template>

<script setup lang="ts">
import CustomHeadlessDisclosure from "~/components/Elements/CustomHeadlessDisclosure.vue";
import PrimaryGradientButton from "~/components/Elements/PrimaryGradientButton.vue";
import PrimaryGradientLink from "~/components/Elements/PrimaryGradientLink.vue";
import TheFooter from "~/components/TheFooter.vue";
import { ANIMATION_URLS, CLIENT_URLS, FAQ, FEATURES_INFO, IMAGE_URLS, INSTRUCTORS_INFO, LEARNERS_INFO } from "~/constants";

import bottom from "../assets/svg/bottom.svg";
import TheHeader from "~/components/TheHeader.vue";

definePageMeta({
  layout: "empty",
});

const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};

const breakpoints = {
  768: {
    itemsToShow: 2,
    snapAlign: "center",
  },

  1024: {
    itemsToShow: 3,
    snapAlign: "center",
  },

  1280: {
    itemsToShow: 3.5,
    snapAlign: "center",
  },
};

const email = useRuntimeConfig().public.contactEmail;

useHead({
  link: [
    {
      rel: "preload",
      as: "image",
      href: "/webp/background-image.webp",
    },
    {
      rel: "preload",
      as: "image",
      href: "/webp/planet.webp",
    },
  ],
});
</script>

<style>
#landing-page * {
  font-family: "DM Sans", sans-serif;
}

.secure {
  font-weight: 700;
  background: linear-gradient(90deg, #ff7eb5 10.22%, #5a9ef0 89.75%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hover-home-purple:hover {
  --tw-shadow-color: #8c46f3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.hover-home-red:hover {
  --tw-shadow-color: #fd62a4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.hover-home-green:hover {
  --tw-shadow-color: #58e0b0 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.star::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-30%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}
</style>
