import { useAuthStore } from "~/store/authStore";

export default defineNuxtRouteMiddleware(async () => {
  await callOnce(
    async () => {
      const authStore = useAuthStore();

      if (authStore.shouldRefreshAuthUser()) {
        await authStore.getAuthUser();
      }
    },
    { mode: "navigation" },
  );
});
