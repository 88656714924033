<template>
  <footer class="bg-[#030514] px-6 md:px-20" :class="background">
    <div class="flex justify-center border-b border-white/[0.2] py-3 sm:py-12">
      <img :src="IMAGE_URLS.FullLogoWhite" class="w-32" alt="Full white logo" />
    </div>
    <div class="flex flex-col-reverse justify-between gap-y-4 py-3 sm:gap-y-6 sm:py-12 lg:flex-row">
      <p class="w-full text-center text-sm font-bold text-white lg:w-1/3 lg:text-left">
        © {{ new Date().getFullYear() }} {{ siteName }}. All Rights reserved
      </p>
      <div class="flex w-full items-center justify-center gap-4 sm:gap-10 lg:w-1/3">
        <nuxt-link aria-label="Facebook link" to="https://www.facebook.com/profile.php?id=61560219496175&mibextid=LQQJ4d" target="_blank" external>
          <nuxt-icon name="social/facebook-filled" class="text-2xl descendant:text-white descendant:hover:text-home-purple" />
        </nuxt-link>
        <nuxt-link aria-label="Instagram link" to="https://www.instagram.com/learnerverse.ai" target="_blank" external>
          <nuxt-icon name="social/instagram-filled" class="text-2xl descendant:text-white descendant:hover:text-home-purple" />
        </nuxt-link>
        <nuxt-link aria-label="Twitter link" to="https://twitter.com/learnerverseai" target="_blank" external>
          <nuxt-icon name="social/twitter-filled" class="text-2xl descendant:text-white descendant:hover:text-home-purple" />
        </nuxt-link>
      </div>
      <div class="flex w-full flex-wrap justify-center gap-4 md:items-center md:gap-10 lg:w-1/3 lg:justify-end">
        <nuxt-link v-if="siteMeta.betaMode === 0" class="text-sm text-white hover:text-home-purple" :to="CLIENT_URLS.Feed">Content Feed</nuxt-link>
        <nuxt-link class="text-sm text-white hover:text-home-purple" :to="CLIENT_URLS.Privacy">Privacy Policy</nuxt-link>
        <nuxt-link class="text-sm text-white hover:text-home-purple" :to="CLIENT_URLS.TOS">Terms of Service</nuxt-link>
        <nuxt-link class="text-sm text-white hover:text-home-purple" :to="`mailto:${email}`" external>Contact Us</nuxt-link>
      </div>
    </div>
  </footer>

  <div class="flex items-center justify-center gap-1 py-4">
    <span>Made with</span>
    <nuxt-icon name="heart-filled" class="text-2xl descendant:text-home-red" />
    <span>from</span>
    <TheIcon display classes="cursor-pointer w-12" />
  </div>
</template>

<script setup lang="ts">
import { CLIENT_URLS, IMAGE_URLS } from "~/constants";
import TheIcon from "~/components/PartialComponents/TheIcon.vue";

defineProps<{ background?: string }>();

const { contactEmail: email, siteName, siteMeta } = useRuntimeConfig().public;
</script>
