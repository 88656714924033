<template>
  <img v-show="store.isDark && props.display" :class="classes" src="../../assets/svg/icon-white.svg" alt="Logo icon" @click="navigateTo('/feed')" />
  <img v-show="!store.isDark && props.display" :class="classes" src="../../assets/svg/icon.svg" alt="Logo icon" @click="navigateTo('/feed')" />
</template>

<script setup lang="ts">
import { useThemeStore } from "~/store/themeStore";

const props = defineProps<{ display: boolean; classes?: string }>();

const store = useThemeStore();
</script>
